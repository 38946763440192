
$( document ).on('turbolinks:load', function() {
    $('#link-top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
    

    
    $("#close-video").click(function () {
        var video = document.getElementById("videoComplete");
        video.pause();
    });
});


function disableScroll(){  
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function(){ window.scrollTo(x, y) };
}

function enableScroll(){  
    window.onscroll = null;
}











